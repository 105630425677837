*{position:relative;box-sizing:border-box}
body {padding:0;margin:0}
.preview_animation {
  width: 85px;
  padding: 5px;
  display: flex;
	align-items: center;
	justify-content: center;
}
.loadPDF {
  width:74px;
  height:50px;
  background: #fff;
  perspective: 150px;
  box-shadow: 5px 5px 8px 3px rgba(0,0,0,.5);
}

.loadPDF *{
    display:block;
    transform-origin: 0 0;
}

.loadPDF p {
    box-shadow:0 0 4px 0 rgba(0,0,0,.25);
    margin:0;
    float:left;
    width:50%;
    height:100%;
    background:inherit;
    padding:12px 6px;
    border-radius:2px;
}

.loadPDF p:nth-child(1){
    border-right: 1px solid #ddd;
}

.loadPDF p:nth-child(2){
    border-left: 1px solid #ccc;
    animation: page 5s ease-in-out infinite;
}

.loadPDF p:nth-child(2) u{
    transform:scaleX(0);animation:line1 5s ease-in-out infinite
}

.loadPDF p:nth-child(2) u:nth-child(2){animation-name:line2}

.loadPDF p:nth-child(2) u:nth-child(3){animation-name:line3}

.loadPDF p:nth-child(2) u:nth-child(4){animation-name:line4}

.loadPDF u{
    width:100%;
    height:2px;
    background: #666;
    margin:5px 0;
}

@keyframes page {
  0%,80% {transform:rotateY(0deg)}
  98%,100% {transform:rotateY(-180deg)}
}
@keyframes line1 {0%{transform:scaleX(0)} 15%,100%{transform:scaleX(1)}}
@keyframes line2 {0%,20%{transform:scaleX(0)} 35%,100%{transform:scaleX(1)}}
@keyframes line3 {0%,40%{transform:scaleX(0)} 55%,100%{transform:scaleX(1)}}
@keyframes line4 {0%,60%{transform:scaleX(0)} 75%,100%{transform:scaleX(1)}}
