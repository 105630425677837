
.MuiAccordionDetails-root{
    width: auto;
}
.paper-listado {
    padding: 0 20px;
    color: #000;
    background-color: #fff;
    min-width: 80px;    
    vertical-align: middle;
    margin-bottom: 20px;
    flex: 1;
}

.form-button {
    color: #000;
    width: 'auto';
    min-width: 80px;
    border: #00DEFF solid 2px;
    margin: 20px;
    margin-bottom: 15px;
    margin-top: 0px;
    border-radius: 15px;
    /*box-shadow: 3px 3px #ff972b;*/
    padding: 10px;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    transition: all .3s ease;
}

.form-button-text {
    margin-top: 0px;
    font-size: 18px;
    font-weight: bold;
}

.form-button:hover {
    background-color: rgba(0, 222, 255, 0.3);
}

.color-sky-blue>label{
    color: #00D3FF !important;
}

.MuiFormControl-root>label, .color-sky-blue>label{
    background-color: transparent;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 14px;
    font-weight: bold;
    /* font-family: 'Montserrat', sans-serif !important; */
}

.option-button{
    background-color: #00D3FF !important;
    color: #fff !important;
    border-radius: 10px !important;
    height: 44px;
}
.option-button-orange{
    background-color: #ff972b !important;
}

.option-button-red{
    background-color: #ff0000 !important;
}

.option-button-disabled {
    background-color: #ddd !important;
}

.MuiFormLabel-asterisk,  div.MuiInputBase-root > fieldset > legend {
    font-size: 15px;
    font-weight: bolder;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(10px, -13px) scale(0.9) !important;
}
.datepicker-100 .MuiInputLabel-shrink{
    transform: translate(0, 1.5px) scale(0.9) !important;
}