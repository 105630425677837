
.dialog-data .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
    max-width: 1222px !important;
    width: 60% ;
}


.dialog-pdf .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
    max-width: 1222px !important;
    width: 80%;
    height: 100%;
}

.dialog-pdf .MuiDialogContent-root {
    display: flex;
    justify-content: center;
}

.dialog-little .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
    width: 550px;
}

.dialog-data .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded,
.dialog-little .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded,
.dialog-pdf .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
    border-radius: 25px;
    border: 0px solid #00D3FF;
}
.dialog-warning .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded{
    width: auto;
}
.dialog-warning .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded h2{
    text-transform: uppercase;
}
.MuiDialogContent-root form{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}
.dialog-little .MuiDialogContent-root form{
    flex-direction: column;
}
.MuiDialogActions-root{
    justify-content: center !important;
    margin-bottom: 20px;
}
.confirm-dialog{
    background-color: #ff972b !important;
    color: white !important;
    width: 150px;
    font-weight: bold !important;
}
.confirm-dialog-disable{
    background-color: #ddd !important;
}

.cancel-dialog{
    color: black !important;
    width: 150px;
    background-color: #ccc !important;
    border: 0px solid #00D3FF !important;
    font-weight: bold !important;
}
.MuiCheckbox-root svg, .table-menu-button svg, .dialog-data .MuiIconButton-label>svg{
 color: #ff972b;
}

.MuiTypography-h6, .title-header{
    /* font-family: 'Montserrat', sans-serif !important; */
    font-weight:bold !important; 
    font-size:18px !important;
    text-transform: lowercase;
}

.MuiTypography-h6:first-letter, .title-header::first-letter {
    text-transform: uppercase !important;
}
.dialog-data .MuiAccordionDetails-root {
    width: 95%;
}

@media only screen and (max-width: 559px) {

    .form-dialog-data .MuiAccordionDetails-root {
        box-sizing: border-box;
        padding-left: 0px;
        padding-right:0px;
        margin-left: auto;
        margin-right: auto;
    }

    .form-dialog-data .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-rounded {
        hyphens: auto;
    }
  }