.home-container {
    position: relative;
}

.background-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 145px;
    overflow: hidden;
    z-index: -1;
}

.App-title {
    font-size: 25px;
    font-weight: bold;
    padding-left: 20px;
    padding-bottom: 10px;
    color: rgb(0, 43, 97);
    position: relative;
    margin-top: 10px;
}

.Home-header-webBar{
    align-items: center;
    background-color: #cccccc;
    height: 75px;
    position: fixed;
    top:75px;
    width: 100%;
    z-index: 50;
  }

.background-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.65;
    z-index: 2;
}

.image-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
}

.home-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    overflow-y: auto;
}

.header-content {
    padding: 10px 15px;
    color: #fff;
}

.vl {
    border-left: 1px solid whitesmoke;
}

.background-logo {
    width: 4em;
    height: auto;
    vertical-align: middle;
}

.maps-container {
    margin: -20px;
    margin-top: 20px;
    margin-bottom: -120px;
}

.home-button {
    color: #000;
    background-color: #fff;
    display: inline-block;
    height: 110px;
    margin: 20px;
    margin-bottom: 15px;
    margin-top: 0px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
}

.frame-img {
    height: 90%;
    position: relative;

}

.feature-icon-wrapper{
    width: 80px;
    height: 48px;
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
}

.feature-icon {
    height: 'auto';
    width: 'auto';
    max-width: 45px;
    max-height: 35px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.feature-icon-home {
    height: auto;
    width: 50%;
    max-width: 100px;
    max-height: 75px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
}

.feature-name {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.15px;
    max-width: 60vw;
    white-space: nowrap;
    overflow: auto;
}

.button-text {
    margin-top: 0px;
    font-size: 12px;
    font-weight: bold;
}

.dynamic-grid {
    display: grid;
    grid-gap: 10px;
    grid-auto-rows: 150px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-columns: 20px;
}

.bg-companies{
    height: calc(-150px + 100vh);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    background-image: url("../Assets/PRL_bg_emp.png");

}
/* div.bg-companies::before {
    content: '';
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
} */

/* .bg-companies-02 {
    content: '';
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
} */

.buttons-container {
    position: absolute;
    top:0px;
    margin-top: 0px;
    z-index: 0;
    overflow-y: auto;
    width: 100%;
    height: 98%;
}

.news-container {
    height: 89%;
    margin: 10px;
    z-index: 0;
    overflow-y: auto;
}

.new-card {
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 10px;
    color: #000;
    font-size: 1.1rem;
}

.new-card-not-viewed {
    background-color: rgb(0, 211, 255, 0.60);
}

.new-card-viewed {
    background-color: rgb(187, 187, 187);
}

.Home-basic {
    margin-top: 150px;
    height: 75px;
}

@media only screen and (max-width: 468px) {
    .feature-name {
      max-width: 58vw;
    }
}

@media only screen and (max-width: 390px) {
    .feature-name {
      max-width: 52vw;
    }
  }
