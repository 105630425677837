.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;
}
@media (min-width: 600px){
    .MuiToolbar-gutters {
        padding-left: 60px;
        padding-right: 60px;
    }
}
