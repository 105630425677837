.filter-item{
  width: calc(11.111% - 15px);
  min-width: 150px;
}
.filter-item-200px{
  width: 200px;
}
.datepicker-100{
  width: 100%;
}
.dialog-item-25{
  width: calc(25% - 20px);
}
.dialog-item-100{
  width: 100%;
}
.clear-filter{
  border-radius: 10px;
  background-color: #00D3FF;
  margin-right: 15px;
  /* display: none; */
}

.filter-show {
  display: none;
}

.clear-filter svg, .App-header-options-item svg{
  fill: white;
}
.MuiOutlinedInput-root{
  border-radius: 10px !important;
}

.filter-list{
  fill: #00D3FF !important;
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.filter-list svg.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined{
  fill: #00D3FF !important;
}

.filter-list .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00D3FF !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00D3FF !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #00D3FF !important;
}

.filter-list .MuiFormLabel-root.Mui-focused {
  color: #00D3FF !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #00D3FF !important;
}

.MuiPickersDay-daySelected {
  background-color: #00D3FF !important;
}

.MuiTypography-colorPrimary {
  color: #00D3FF !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #00D3FF !important;
}

.filter-list-toolbar{
  flex: 1px;
  align-self: center;
  gap: 15px;
  overflow-x: auto;
  margin-left: 5px;
  margin-right: 10px;
  padding-Top: 5px;
  padding-Bottom: 5px;
}


.MuiSelect-icon.Mui-disabled {
  color:#000;
  display: none;
}

.MuiInputBase-input.Mui-disabled {
  color:#000;
}

.filter-list-mobile button.confirm-dialog {
  display: none;
}

div.filter-list-responsive .clear-filter-responsive:has(button:nth-last-child(n + 2)) button:first-of-type {
  display: none;
}

@media only screen and (max-width: 779px) {  

  .App-header-options-responsive {
    max-width: none;
    width: 100%;
  }

  div.filter-list-responsive {
    width: auto;
    background: white;
  }

  div.filter-list-responsive .clear-filter-responsive {
    justify-self: flex-end;
    display: flex;
    gap: 10px;
    margin-right: 0px;
    background-color: #FFFFFF;
    margin-right: 0px;
  }

  div.filter-list-responsive .clear-filter-responsive button {
    background-color: #00DEFF;    
    border-radius: 10px; 
  }

  div.filter-list-responsive div.MuiToolbar-root.filter-list-toolbar-responsive {
    display: none;
  }

  div.filter-list-responsive .clear-filter-responsive:has(button:nth-last-child(n + 2)) button:first-of-type {
    display: block;
  }
}

.filters-dialog .MuiFormControl-root {
  width: 100%;
}
