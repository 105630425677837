.container{
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: hidden;
}
.boxTitle{
    font-weight: bold;
    margin-bottom: 0;
}

.boxValue{
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin: .5rem 0;
}
.dashboard-section-data-wrapper {
    display: flex;
    gap: 50px;
    margin-bottom: 35px;
}
.dashboard-graphics-container,.dashboard-kpi-container{
    display: flex;    
    align-items: center;    
    gap: 50px;
    flex-wrap: wrap;
}
.dashboard-graphic, .dashboard-kpi{
    background-color: #fff;
    color: #000;    
    width: 420px;
    border-radius: 15px;
    padding: 1rem;    
    align-self: stretch;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 300px;
}
.dashboard-graphic-wrapper{
    height: 260px !important;
    top: -2em;
}
.dashboard-kpi-wrapper{
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dates-container{
    display: flex;
    gap: 15px;
    max-width: 100%;
}

@media only screen and (max-width: 1440px) {    
    .dashboard-section{
        padding: 1rem;
    }
    .dashboard-section-data-wrapper{
        flex-direction: column;
    }
    .dashboard-graphics-container, .dashboard-kpi-container{
        width: 100%;
    }
    .dashboard-kpi-container{
        flex-direction: row;
    }
    .dashboard-kpi {
        scroll-snap-align: center;
        width: 100%;
        min-width: 300px;
    }
    .dashboard-graphics-container{
        justify-content: center;
    }
    .dashboard-graphic{
        align-self: center;
    }
}

@media only screen and (max-width: 450px) {
    .dashboard-section{
        padding: 1rem 0;
    }    
    .dashboard-kpi-container{        
        flex-direction: column;
    }
    .dashboard-kpi-wrapper{
        height: 115px;
    }
    .dashboard-kpi {                
        min-width: 80dvw;
        height: 190px;
    }
    .dashboard-graphic, .dashboard-kpi{
        width: 80dvw;
        align-self: center;
    }
}