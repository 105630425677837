.App-menu-button{
    display: none;
}
@media only screen and (max-width: 1500px){
    .App-menu-button{
        display: block;
        position: absolute;
        right: 10px;
    }   
}
