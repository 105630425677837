div.MuiDialog-container.MuiDialog-scrollPaper .dialog-item-25 .datepicker-100 {
    min-width: 200px;
}

@media only screen and (max-width: 779px) {
    div.MuiDialog-container.MuiDialog-scrollPaper div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        max-width: 100%;
        width: 92%;
        margin: 0;
    }  
    
    div.MuiDialog-root.dialog-data {
        padding: 0px !important;
    }
}

@media only screen and (max-width: 468px) {
    div.MuiDialog-container.MuiDialog-scrollPaper .dialog-item-25 {        
        width: 100%;   
    }
}