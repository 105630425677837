:root{
    --fc-button-bg-color: #00d3ff;
    --fc-button-border-color: #00d3ff;
    --fc-button-hover-bg-color: #00aacd;
    --fc-button-hover-border-color: #00aacd;
    --fc-button-active-bg-color: #00aacd;
    --fc-button-active-border-color: #00aacd;
}
.calendarItemPriority{
    height: 10px;
    display: inline-block;
    width: 10px;    
    border-radius: 50%;
    margin: 0 5px;
}
.calendarItemState{
    display: flex;
    align-items: center;
}
.calendarItemState svg{
    height: .6em !important;
}
a.correctivos{
    border-color: #ff9000;
    background-color: #ff90003b;
    cursor: pointer;
    overflow: auto;
}
a.preventivos{
    border-color: #00d3ff;
    background-color: #00d3ff66;
    cursor: pointer;
    overflow: auto;
}
.fc-h-event .fc-event-main{
    color: #000;
}
.fc .fc-popover{
    z-index: 3 !important;
}
.legend{
    display: flex;
    justify-content: start;
    margin-top: 10px;
    gap: 1em;
}
.legend>span::before{
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}
.legend>span.preventivos::before{
    background-color: #00d3ff;
}
.legend>span.correctivos::before{
    background-color: #ff9000;
}
.fc .fc-toolbar.fc-header-toolbar{
    gap: .75em;
}
.fc-toolbar-chunk{
    display: flex;    
}
div.fc-view-harness.fc-view-harness-active > .fc-timegrid > table > tbody > tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid{display: none !important;}
@media screen and (max-width: 450px){
    .fc .fc-toolbar-title, .fc .fc-button .fc-icon, .fc .fc-button .fc-text{
        font-size: 1.25em;
    }
    .fc-button{
        font-size: .85em !important;
    }    
}
@media screen and (max-width:400px) {
    .fc-today-button{
        display: none;
    }
}