.jobplace-epis-container {
    gap: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    /* height: 75px; */
    overflow-x: auto;
}

.jobplace-epis-icon, .jobplace-epis-icon-popup {
    cursor: pointer;
    border: solid 2px transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
    transition: border .3s ease;
    min-width: 95px;
    height: 113px;    
}

.jobplace-epis-icon-popup{
    cursor: unset;
}

.jobplace-epis-icon:hover{
    border: solid 2px #00DEFF;
}

.jobplace-epis-icon img, .jobplace-epis-icon-popup img {
    object-fit: contain;
    padding: 5px;
}

.jobplace-epis-icon-info {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 3px;
    font-size: 14px;
}

/* .jobplace-epis-icon:hover .jobplace-epis-icon-info {
    display: block;
} */

.jobplace-epi-detail:first-letter{
    text-transform: capitalize
}

.addSectionButton {
    background-color: #00DEFF;
    border-radius: 10px;
    height: 56;
    color: white;
    align-items: center;
    width: 200;
}
.MuiTabs-indicator{
    background-color: transparent !important;
}
.MuiTabs-flexContainer{
    gap: 1rem;
}
@media only screen and (max-width: 570px) {
    .MuiTabs-flexContainer{
        justify-content: unset;        
    }
}