.Form-section {
    background-color: rgb(40, 44, 52, 0.5);
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    vertical-align: middle;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: white;
  }