
@import url('https://fonts.googleapis.com/css?family=Montserrat:wght@300;400;700;900&display=swap');

.lm-overlay-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

/* define a fixed width for the entire menu */
.navigation {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 150px;
  left: 0;
  background-color: #808080;
  overflow-x: hidden;
  padding-top: 20px;
  cursor: pointer;
  transition: 0.5s;
}

.mainmenu li div.hasSubMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

div.hasSubMenu svg{
  fill:#f1f1f1;
}
div.hasSubMenu:hover{
  fill: #FF9000;
}

/* reset our lists to remove bullet points and padding */
.mainmenu, .submenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* make ALL links (main and submenu) have padding and background color */
.mainmenu a {
  padding: 19px 20px;
  text-decoration: none;
  font-size: 17px;
  color: #f1f1f1;
  display: block;
  transition: all .3s ease;
  /* font-family: 'Montserrat', sans-serif; */
}

/* add hover behaviour */
.mainmenu a:hover {
  color: #FF9000;
}

.mainmenu a.active{
  color: #808080;
  background-color: #FF9000;
  font-weight: bold;
}

/* when hovering over a .mainmenu item,
  display the submenu inside it.
  we're changing the submenu's max-height from 0 to 200px;
*/

.mainmenu li:hover .submenu {
  display: block;
  max-height: 200px;
}

/*
  we now overwrite the background-color for .submenu links only.
  CSS reads down the page, so code at the bottom will overwrite the code at the top.
*/

.submenu a {
  background-color: #999;
}

/* hover behaviour for links inside .submenu */
.submenu a:hover {
  background-color: #666;
}

/* this is the initial state of all submenus.
  we set it to max-height: 0, and hide the overflowed content.
*/
.submenu {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
}


@media only screen and (max-width: 1500px) {
  /* .navigation{
    display: none;
  } */
  .navigation-hide {
    width: 0px;
  }
}

@media only screen and (min-width: 1501px) {
  /* .navigation{
    display: none;
  } */
  .navigation-hide {
    width: 250px;
  }
}