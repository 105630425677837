
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body-webBar{
  margin: 0 20px 0 270px;
}

.App-basic {
  padding-top: 150px;
  height: 75px;
}

.App-header-webBar{
  display: flex;
  gap: 15px;
  padding-left: 20px;
  align-items: center;
  background-color: #cccccc;
  height: 75px;
}

.comment-card {
  border-radius: 10px;
  padding: 10px;
  border: #00DEFF 1px solid;
  width: 100%;
}
.App-header {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  vertical-align: middle;
  margin-bottom: 5px;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  color: #282c34;
  position: relative;
}

.App-header-options {
  margin-top: 15px;
  display: flex;
  gap: 16px;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.App-header-options.App-header-options-responsive {
  flex-direction: row;
  justify-content: end;
  gap: 10px;
}

.App-header-options-item {
  width: 70px;
  background-color: #00DEFF;
  border-radius: 10px;
  height: 54px;
  color: white;
  cursor: pointer;
}

.App-header-options-item-create {
  width: auto;
  font-size: 18px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
}

.App-header-options-item-disable {
  background-color: #ddd;
  cursor: default;
}

.App-sub-header {
  background-color: rgb(40, 44, 52, 0.5);
  height: auto;
  margin-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  vertical-align: middle;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Hide element on Desktop */
@media only screen and (min-width: 2001px) {
  .hide-on-desktop {
      display: none !important;
  }
}

.App-header-options.App-header-options-responsive .App-header-options-item {
  max-height: 48px;
}

.App-header-options.App-header-options-responsive .App-header-options-item {
  max-height: 48px;
}

.App-header-options.App-header-options-responsive .App-header-options-item {
  width: auto;
}

@media only screen and (max-width: 376px) {
  .App-header-options.App-header-options-responsive .App-header-options-item.App-header-options-item-create {
    font-size: 14.5px;
  }  
}


/* Hide element on Tablet/Phone */
@media only screen and (max-width: 1500px) {
  .hide-on-mobile-tablet {
      display: none !important;
  }
  .App-body-webBar{
    margin: 20px;
  }
}

@media only screen and (max-width: 559px) {

  .App-header-options.App-header-options-responsive .makeStyles-root-4 {
    width: 100%;
    max-width: none;
  }
}

.button-save {
  margin-top: 15px;
  display: inline;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px; 
  color: #fff;
  font-weight: 'bold'; 
  background-color: #003B71;
  border: 0px solid;
  border-radius: 5px;
  cursor: pointer;
}

.MuiSwitch-track{
  background-color: #fff !important;
}